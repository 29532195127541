import Cookies from 'universal-cookie';

interface UtmValues {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_term?: string;
}

export const getUtmFromCookies = (defaultValues?: UtmValues) => {
    const cookies = new Cookies();
    return {
        utm_source: cookies.get('utm_source') || defaultValues?.utm_source || '',
        utm_medium: cookies.get('utm_medium') || defaultValues?.utm_medium || '',
        utm_campaign: cookies.get('utm_campaign') || defaultValues?.utm_campaign || '',
        utm_content: cookies.get('utm_content') || defaultValues?.utm_content || '',
        utm_term: cookies.get('utm_term') || defaultValues?.utm_term || '',
    };
};

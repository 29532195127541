import * as Sentry from '@sentry/gatsby';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';
import * as React from 'react';
import Cookies from 'universal-cookie';

import { trackUtmParameters } from './script/utmTracker';
import MeetUsContextProvider from './src/contexts/MeetUsContextProvider';
import { identifyHubspot } from './src/tracking/identifyHubspot';
import { trackCustomBehavioralEventHubspot } from './src/tracking/trackCustomBehavioralEventHubspot';
import { sendLoggerRequest } from './src/utils/IdentifyLogger';
import { linkResolver } from './src/utils/linkResolver';
import { getUtmFromCookies } from './src/utils/utmHelper';
export const onInitialClientRender = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    adBlockDetector && adBlockDetector();

    const dataLayer = window.dataLayer || [];
    dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    if (window?.Intercom) {
        window.Intercom('onUserEmailSupplied', function () {
            const intercom_iframe = document?.querySelector('.intercom-messenger-frame')?.children[0];
            const email = intercom_iframe?.contentDocument?.body.querySelector('input[type="email"]')?.value;

            sendLoggerRequest({
                email,
                source: 'Intercom',
            });

            identifyHubspot(
                email,
                getUtmFromCookies({
                    utm_source: 'intercom',
                }),
            );
            trackCustomBehavioralEventHubspot('pe9258772_marketing_website_intercom_email_supplied', {
                ...getUtmFromCookies(),
                email: email,
            });
        });
    }

    Sentry.init({
        dsn: process.env.GATSBY_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.httpClientIntegration()],

        tracePropagationTargets: ['localhost', /^https:\/\/dougs\.fr/],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        // Learn more at
        // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
        tracesSampleRate: 0.1,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // Learn more at
        // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};

export const onRouteUpdate = location => {
    mixpanel.init(process.env.GATSBY_MIXPANEL_API_KEY, { debug: process.env.GATSBY_MIXPANEL_DEBUG_MODE === 'true' });

    trackUtmParameters();

    if (mixpanel) {
        const cookies = new Cookies();
        const dg_referral = cookies.get('dg_referral');

        mixpanel.track('Revenue Marketing WebSite Page Viewed', {
            Platform: 'Marketing WebSite',
            URL: location.location.pathname,
            dg_referral,
            branch: process.env.BRANCH,
        });
    }
};

const repositoryConfigs = [
    {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver: linkResolver,
        componentResolver: {
            post: React.lazy(() => import('./src/templates/postPrismic')),
            video: React.lazy(() => import('./src/templates/video')),
            tag: React.lazy(() => import('./src/templates/tagPrismic')),
            author: React.lazy(() => import('./src/templates/authorPrismic')),
            guide: React.lazy(() => import('./src/templates/guidePrismic')),
            category: React.lazy(() => import('./src/templates/categoryPrismic')),
        },
    },
];

export const wrapRootElement = ({ element }) => {
    return (
        <MeetUsContextProvider>
            <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
        </MeetUsContextProvider>
    );
};

export const sendLoggerRequest = async ({
    email,
    source,
}: {
    email: string;
    source: 'Intercom' | 'Hubspot' | 'Calendly';
}) => {
    try {
        await fetch('/.netlify/functions/logger', {
            method: 'POST',
            body: JSON.stringify({
                email,
                source,
                referrer: document.referrer || '',
                cookies: document.cookie || '',
            }),
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            redirect: 'follow' as RequestRedirect,
        });
    } catch (error) {
        console.error('Error in sendLoggerRequest:', error);
        throw error;
    }
};
